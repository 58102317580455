import React, { useState } from "react";

import {
  StyledEngineProvider,
  Grid,
  Button,
  Divider,
  Typography,
  IconButton,
  Tooltip,
  ThemeProvider,
  useTheme,
  Avatar,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { SUPPORT_WEBSITE } from "@aclymatepackages/constants";
import { TextField, LoadingButton } from "@aclymatepackages/atoms";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { mainTheme } from "@aclymatepackages/themes";

import SlideLayout from "../../layouts/SlideLayout";

import { sendSupportEmail } from "../../../helpers/utils/apiCalls";
import { analyticsTrack } from "../../../helpers/analytics";

const TopBarSupportIcon = ({
  email,
  userName,
  companyName,
  showHelpCenter = true,
  color,
  origin = "platform",
}) => {
  const { palette } = useTheme();

  const [supportSliderOpen, setSupportSliderOpen] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [inputData, setInputData] = useState({});
  const { subject, message } = inputData;

  const isPlatform = origin === "platform";

  const editInputData = (field) => (value) =>
    editObjectData(setInputData, field, value);

  const onEmailSend = () => {
    setSendEmailLoading(true);
    return sendSupportEmail({
      name: userName,
      email,
      companyName,
      subject,
      message,
      callback: () => {
        setIsEmailSent(true);
        setSendEmailLoading(false);
      },
    });
  };

  return (
    <>
      {supportSliderOpen && (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={mainTheme}>
            <SlideLayout
              isSlideOpen
              setIsSlideOpen={setSupportSliderOpen}
              header={
                <Typography variant="h5" color="textPrimary">
                  Let us help you find what you need
                </Typography>
              }
              content={
                <Grid container direction="column" spacing={2} wrap="nowrap">
                  {showHelpCenter && (
                    <>
                      <Grid item container justifyContent="center" spacing={2}>
                        <Grid item>
                          <Button
                            color="primary"
                            variant="contained"
                            href={SUPPORT_WEBSITE}
                            target="_blank"
                          >
                            Visit our help center
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                              setSupportSliderOpen(false);
                              analyticsTrack("tour replay");
                            }}
                          >
                            Replay product tour
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item xs={5}>
                          <Divider />
                        </Grid>
                        <Grid item={1}>
                          <Typography
                            variant="subtitle1"
                            color="textSecondary"
                            align="center"
                          >
                            OR
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Divider />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid item sm={12}>
                    <Typography variant="h6" color="textPrimary">
                      Email us directly
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <TextField
                      label="Subject"
                      value={subject}
                      setValue={editInputData("subject")}
                    />
                  </Grid>
                  <Grid item sm={12}>
                    <TextField
                      label="Message"
                      multiline
                      rows={10}
                      value={message}
                      setValue={editInputData("message")}
                    />
                  </Grid>
                  {isEmailSent && (
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        align="center"
                      >
                        We've received your email and we'll get back to you
                        within one work day
                      </Typography>
                    </Grid>
                  )}
                  <Grid item container justifyContent="center">
                    <LoadingButton
                      isLoading={sendEmailLoading}
                      variant="contained"
                      color="secondary"
                      disabled={!subject || !message || isEmailSent}
                      onClick={onEmailSend}
                      label="Email Support"
                    />
                  </Grid>
                </Grid>
              }
            />
          </ThemeProvider>
        </StyledEngineProvider>
      )}
      <Grid item>
        <Tooltip title="Aclymate Help Center">
          <span>
            <IconButton
              style={{
                color: palette.text.primary,
              }}
              onClick={() => {
                setSupportSliderOpen(true);
                setInputData({});
                return setIsEmailSent(false);
              }}
              size="large"
            >
              {isPlatform ? (
                <HelpOutlineIcon fontSize="large" />
              ) : (
                <Avatar
                  sx={{
                    backgroundColor: color || palette.secondary.main,
                  }}
                >
                  <QuestionMarkIcon
                    fontSize="small"
                    style={{
                      color: color || "white",
                    }}
                  />
                </Avatar>
              )}
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
    </>
  );
};
export default TopBarSupportIcon;
