import React from "react";
import dayjs from "dayjs";

import { formatDollars } from "@aclymatepackages/formatters";

import { tonsToLbs } from "@aclymatepackages/converters";
import findSubcategory from "@aclymatepackages/subcategories";

import DownloadButton from "./DownloadButton";

const splitUtilitiesEmissions = (emissions) =>
  emissions.flatMap((emission) => {
    const { subcategory, details = {}, date, source } = emission;

    if (subcategory !== "utilities" || source !== "recurring") {
      return emission;
    }

    const {
      companyOfficeElectricTons = 0,
      companyOfficeGasTons = 0,
      totalHomeOfficeTons = 0,
      defaultEmissions = 0,
    } = details;

    const monthYearName = `${dayjs(date).format("MMMM")} ${dayjs(date).year()}`;
    const totalOtherEmissionsTons = defaultEmissions + totalHomeOfficeTons;

    return [
      {
        date,
        subcategory: "gas",
        name: `${monthYearName} Office Gas Utilities`,
        tonsCo2e: companyOfficeGasTons * -1,
        lbsCo2: tonsToLbs(companyOfficeGasTons),
        scope: 1,
      },
      {
        date,
        subcategory: "electricity",
        name: `${monthYearName} Office Electric Utilities`,
        tonsCo2e: companyOfficeElectricTons * -1,
        lbsCo2: tonsToLbs(companyOfficeElectricTons),
        scope: 2,
      },
      {
        date,
        subcategory: "home-office",
        name: ` ${monthYearName} Other Utilities`,
        tonsCo2e: totalOtherEmissionsTons * -1,
        lbsCo2: tonsToLbs(totalOtherEmissionsTons),
        scope: 3,
      },
    ];
  });

const formatCsvEmissions = (emissions = []) =>
  emissions.map((emission) => {
    const { tonsCo2e, date, value } = emission;

    if (!tonsCo2e || isNaN(tonsCo2e)) {
      return { ...emission, tonsCo2e: 0 };
    }

    const negativeTons = Math.abs(tonsCo2e) * -1;

    return {
      ...emission,
      date,
      tonsCo2e: negativeTons,
      lbsCo2: tonsToLbs(negativeTons),
      scope: findSubcategory(emission.subcategory)?.scope,
      value: formatDollars(value),
    };
  });

const EmissionsCsvDownload = ({
  emissions,
  tooltip = "Download Emissions CSV File",
  fileName = `${dayjs().format("MMDDYYYY")}-aclymate-emissions-download.csv`,
}) => {
  const onEmissionsDownload = () => {
    const csvHeader =
      "Date,Name,Category,Value,Status,Tons CO2e,Scope,Office,Vehicle,Employees\r\n";

    const formattedEmissions = splitUtilitiesEmissions(
      formatCsvEmissions(emissions)
    ).map(({ office, vehicle, employees, ...otherProps }) => {
      const officeString = office ? office.name : "";
      const vehicleString = vehicle ? vehicle.name : "";
      const employeesString = employees
        ? employees.map(({ name }) => name).join("|")
        : "";
      return {
        office: officeString,
        vehicle: vehicleString,
        employees: employeesString,
        ...otherProps,
      };
    });

    const sortedEmissions = [...formattedEmissions].sort(
      (a, b) => b.date - a.date
    );

    const csvRows = sortedEmissions
      .map(
        ({
          date,
          currentStatus,
          name,
          subcategory,
          value,
          tonsCo2e,
          scope,
          office,
          vehicle,
          employees,
        }) => {
          const { name: categoryName } = findSubcategory(subcategory);

          return `${date},${name},${categoryName},${value},${
            currentStatus || "confirmed"
          },${tonsCo2e},${scope},${office},${vehicle},${employees}\r\n`;
        }
      )
      .join("");

    return new Blob([`${csvHeader}${csvRows}`], { type: "text/csv" });
  };

  return (
    <DownloadButton
      tooltip={tooltip}
      buildFile={onEmissionsDownload}
      fileName={fileName}
    />
  );
};
export default EmissionsCsvDownload;
