//eslint-disable-next-line
/*global google*/

import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  LinkedinShareButton,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";
import { useParams } from "react-router";
import * as Sentry from "@sentry/react";

import {
  Container,
  Box,
  Grid,
  Typography,
  Paper,
  Divider,
  StyledEngineProvider,
  ButtonBase,
  Chip,
  ThemeProvider,
  Button,
} from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faPlane,
  faHotel,
  faThermometerThreeQuarters,
  faEquals,
  faBus,
  faCar,
  faCampground,
  faUsers,
  faWalking,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFireSmoke,
  faRv,
  faHouse,
  faShuttleVan,
} from "@fortawesome/pro-solid-svg-icons";
import { SUPPORT_WEBSITE } from "@aclymatepackages/constants";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { DefaultPaper, AnimatedLogo } from "@aclymatepackages/atoms";
import mainTheme, {
  useLayoutHelpers,
  mergeDarkTheme,
} from "@aclymatepackages/themes";
import {
  tonsToLbs,
  hexToRgba,
  nameToFirstName,
} from "@aclymatepackages/converters";
import { formatDecimal, formatDollars } from "@aclymatepackages/formatters";
import {
  FootprintEquivalencies,
  // ComparisonChart,
} from "@aclymatepackages/modules";
import { useSharedFormLoading } from "@aclymatepackages/multi-part-form";

import PageSectionPaper from "./PageSectionPaper";
import EventLayout from "./EventLayout";
import EventAttendeeSurvey from "./EventAttendeeSurvey";
import EventOffsetPurchase from "./EventOffsetPurchase";
import PartnerSurvey from "./PartnerSurvey";
import MyAclymateCta from "./MyAclymateCta";

import OffsetDetailsPurchaseSlider from "../modules/projects/OffsetDetailsPurchaseSlider";

import {
  convertCostToPricePerTon,
  formatProject,
} from "../../helpers/components/projects";
import {
  // lastYearsFestivarianTravelDataSummary,
  displayEventOffsetCost,
} from "../../helpers/components/events";
import { fetchOurApi, fetchAdminApi } from "../../helpers/utils/apiCalls";
import Link from "../atoms/mui/Link";

const mainEventCategoryObj = {
  type: "totalEvent",
  color: "secondary",
  icon: faFireSmoke,
  title: "Total Event Emissions",
  primary: true,
};

const EventNotFound = () => {
  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
          gap: 1,
        }}
      >
        <Typography variant="h3" color="error" gutterBottom>
          We couldn’t find this survey.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Please check the URL of the survey and try again. If you believe this
          message is an error, please contact support.
        </Typography>
        <Link href={SUPPORT_WEBSITE}>
          <Button variant="contained" color="primary">
            Contact Support
          </Button>
        </Link>
      </Box>
    </Container>
  );
};

const buildFootprintDetailsCategories = (travelType, lodgingType) => {
  const lodgingIcons = {
    hotel: faHotel,
    property: faHouse,
    tent: faCampground,
    rv: faRv,
    friends: faUsers,
    home: faHouse,
  };

  const travelIcons = {
    flying: faPlane,
    personalCar: faCar,
    publicTransit: faBus,
    carpool: faShuttleVan,
    walking: faWalking,
  };

  return [
    {
      color: "offices",
      icon: lodgingIcons[lodgingType] || faHotel,
      title: "Lodging Emissions",
      type: "lodging",
    },
    {
      color: "vehicles",
      icon: travelIcons[travelType] || faPlane,
      title: "Travel Emissions",
      type: "travel",
    },
    {
      color: "employees",
      icon: faThermometerThreeQuarters,
      title: "Avoided Emissions",
      type: "avoidedEmissions",
    },
  ];
};

const ImpactReportCategorySymbol = ({
  totalCarbonTons,
  title,
  color: colorType,
  primary,
  icon,
}) => {
  const { theme, isMobile, isMedium } = useLayoutHelpers();

  const color = theme.palette[colorType]?.main;

  if (isMobile) {
    return (
      <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="stretch"
          style={{
            border: `solid 3px ${color}`,
            backgroundColor: color,
            borderRadius: "45px",
          }}
        >
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={mergeDarkTheme}>
              <Box
                style={{
                  height: "80px",
                  width: "80px",
                  backgroundColor: "white",
                  borderRadius: "50%",
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <FontAwesomeIcon icon={icon} style={{ color }} size="lg" />
                <Typography variant="h5" style={{ color }} align="center">
                  {formatDecimal(Math.round(tonsToLbs(totalCarbonTons)))}
                </Typography>
              </Box>
              <Box mx={1} display="flex" alignItems="center">
                <Typography variant="h5" color="textPrimary">
                  {title}
                </Typography>
              </Box>
            </ThemeProvider>
          </StyledEngineProvider>
        </Box>
      </Grid>
    );
  }

  const fullScreenCircleSize = primary ? "155px" : "100px";
  const mediumCircleSize = primary ? "100px" : "70px";

  const circleSize = isMedium ? mediumCircleSize : fullScreenCircleSize;

  const formattedCarbonTons = formatDecimal(
    Math.round(tonsToLbs(totalCarbonTons))
  );

  const chooseFontVariant = () => {
    const fontVariants = ["h2", "h4", "h6", "h3", "h5", "subtitle1"];
    const isLongText = formattedCarbonTons.length >= 6;
    const longTextPoints = isLongText ? 3 : 0;
    const screenSizePoints = isMedium ? 1 : 0;
    const primaryPoints = primary ? 0 : 1;
    const variantIndex = primaryPoints + screenSizePoints + longTextPoints;
    return fontVariants[variantIndex];
  };

  return (
    <Grid item>
      <Box p={2}>
        <Grid container direction="column" spacing={2} alignItems="center">
          <Grid item>
            <Box
              p={2}
              style={{
                borderRadius: "50%",
                backgroundColor: color,
                height: circleSize,
                width: circleSize,
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={mergeDarkTheme}>
                  <FontAwesomeIcon
                    icon={icon}
                    size={isMedium ? "lg" : "2x"}
                    style={{ color: "white" }}
                  />
                  <Typography
                    align="center"
                    variant={chooseFontVariant()}
                    color="textPrimary"
                  >
                    {formattedCarbonTons}
                  </Typography>
                </ThemeProvider>
              </StyledEngineProvider>
            </Box>
          </Grid>
          <Grid item>
            <Typography
              variant={isMobile ? "body2" : "subtitle2"}
              align="center"
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

//We're removing this for now since we don't have any data for it
// const ImpactComparison = ({
//   lodging,
//   avoidedEmissions,
//   travel,
//   totalEventTons,
// }) => {
//   const { totalTonsCo2e: festivarianLodgingTons, type: lodgingType } = lodging;
//   const { totalTonsCo2e: festivarianTravelTons, type: travelType } = travel;
//   const { totalTonsCo2e: festivarianAvoidedEmissionsTons } = avoidedEmissions;

//   const {
//     maxFestivarianData,
//     avgTotalEventTons,
//     avgTravelTons,
//     avgLodgingTons,
//     avgAvoidedEmissionsTons,
//   } = lastYearsFestivarianTravelDataSummary();

//   const maxCategories = [
//     mainEventCategoryObj,
//     ...buildFootprintDetailsCategories(travelType, lodgingType),
//   ].map(({ type, ...otherProps }) => ({
//     type,
//     ...otherProps,
//     categoryMaxTons: maxFestivarianData[type],
//   }));

//   return (
//     <PageSectionPaper
//       title="How does your footprint compare to other Festivarians?"
//       annotation
//     >
//       <ComparisonChart
//         comparisonTitle="Average 2022 Festivarian"
//         individualData={{
//           totalEventTons,
//           lodgingTons: festivarianLodgingTons,
//           travelTons: festivarianTravelTons,
//           avoidedEmissionsTons: festivarianAvoidedEmissionsTons,
//         }}
//         comparisonData={{
//           totalEventTons: avgTotalEventTons,
//           lodgingTons: avgLodgingTons,
//           travelTons: avgTravelTons,
//           avoidedEmissionsTons: avgAvoidedEmissionsTons,
//         }}
//         categories={maxCategories}
//       />
//     </PageSectionPaper>
//   );
// };

const ImpactBreakdown = ({
  totalEventTonsCo2e,
  lodging,
  travel,
  avoidedEmissions,
}) => {
  const { isMobile, isMedium } = useLayoutHelpers();

  const {
    totalTonsCo2e: lodgingTons = 0,
    groupSize: lodgingGroupSize,
    type: lodgingType,
  } = lodging;
  const {
    totalTonsCo2e: travelTons = 0,
    groupSize: travelGroupSize,
    type: travelType,
  } = travel;
  const { totalTonsCo2e: avoidedEmissionsTons = 0 } = avoidedEmissions;
  const tonsWithoutAvoidedEmissions = lodgingTons + travelTons;

  const categoryDetailsTons = [
    {
      totalCarbonTons: lodgingTons,
      percent: lodgingTons / tonsWithoutAvoidedEmissions,
      groupSize: lodgingGroupSize,
      type: "lodging",
    },
    {
      totalCarbonTons: travelTons,
      percent: travelTons / tonsWithoutAvoidedEmissions,
      groupSize: travelGroupSize,
      type: "travel",
    },
    {
      totalCarbonTons: avoidedEmissionsTons,
      percent: avoidedEmissionsTons / totalEventTonsCo2e,
      type: "avoidedEmissions",
    },
  ];

  const categoryDetailsInfo = categoryDetailsTons.map(
    ({ type, ...otherProps }) => {
      const categoryObj = buildFootprintDetailsCategories(
        travelType,
        lodgingType
      ).find((category) => category.type === type);
      return { type, ...otherProps, ...categoryObj };
    }
  );

  const reportCategories = [
    {
      ...mainEventCategoryObj,
      totalCarbonTons: totalEventTonsCo2e,
    },
    ...categoryDetailsInfo,
  ];

  const operatorIcons = [faEquals, faPlus, faMinus];

  return (
    <PageSectionPaper
      title="Your Event Footprint Results"
      annotation
      tooltip={
        <>
          <Typography variant="body2">
            <strong>Lodging Emissions:</strong> This is an estimate of your
            lodging emissions while staying at the Event.
          </Typography>
          <Typography variant="body2">
            <strong>Travel Emissions:</strong> This is an estimate of your
            travel emissions to and from the Event.
          </Typography>
          <Typography variant="body2" paragraph>
            <strong>Avoided Emissions:</strong> This is an estimate of the
            amount of carbon you don't produce by attending the Event in
            comparison to your normal activities.
          </Typography>
          <Typography variant="body2">This includes: </Typography>
          <Typography variant="body2">
            - Reducing your household emissions by adjusting your thermostat
            while you are gone
          </Typography>
          <Typography variant="body2">
            - Not driving while at the Event compared to the amount that you
            typically drive on a daily basis
          </Typography>
        </>
      }
    >
      <Grid
        container
        spacing={1}
        justifyContent="space-around"
        alignItems={isMobile ? "stretch" : "center"}
        direction={isMobile ? "column" : "row"}
        wrap="nowrap"
      >
        {reportCategories.map((category, idx) => (
          <React.Fragment key={`impact-report-symbol-${idx}`}>
            <ImpactReportCategorySymbol {...category} />
            {idx !== reportCategories.length - 1 && (
              <Grid item>
                <Box display="flex" justifyContent="center">
                  <FontAwesomeIcon
                    icon={operatorIcons[idx]}
                    size={isMedium ? "1x" : "2x"}
                  />
                </Box>
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </PageSectionPaper>
  );
};

const AttendeeImpactDetails = ({
  eventName,
  totalEventTonsCo2e,
  surveyType,
  ...otherProps
}) => {
  const surveyTypeExclusion =
    surveyType !== "attendee" ? ["carbon-budget"] : [];

  return (
    <>
      {/* <ImpactComparison totalEventTons={totalEventTonsCo2e} {...otherProps} /> */}
      {totalEventTonsCo2e > 0 && (
        <PageSectionPaper title="Emission Equivalents">
          <FootprintEquivalencies
            footprintTons={totalEventTonsCo2e}
            exclusionCategories={["driving-emissions", ...surveyTypeExclusion]}
          />
        </PageSectionPaper>
      )}
    </>
  );
};

const DisplayProjectCard = ({
  name,
  images = [],
  onClick,
  totalThousandLbsCost,
}) => {
  const { theme } = useLayoutHelpers();
  const gradientPrimaryRgb = hexToRgba(theme.palette.primary.main, 1);
  const gradientSecondaryRgb = hexToRgba(theme.palette.secondary.main, 0.5);

  const [hover, setHover] = useState(false);

  return (
    <Grid item sm={6} xs={12}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={mainTheme}>
          <ButtonBase
            onClick={onClick}
            style={{
              width: "100%",
              align: "left",
              height: "215px",
              display: "block",
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Paper
              elevation={hover ? 3 : 1}
              style={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
                aspectRatio: "16/9",
                minHeight: "150px",
              }}
            >
              <Box
                style={{
                  height: "100%",
                  width: "100%",
                  position: "relative",
                  backgroundImage: `url('${images[0]}')`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                }}
                display="flex"
                flexDirection="column"
              >
                <Box
                  position="absolute"
                  style={{ inset: 0 }}
                  display="flex"
                  flexDirection="column"
                >
                  <Box flexGrow={1} p={2} display="flex">
                    <Chip
                      icon={<MonetizationOnIcon />}
                      label={`${formatDollars(
                        convertCostToPricePerTon(totalThousandLbsCost || 0)
                      )} per ton`}
                      style={{
                        backgroundColor: theme.palette.backgroundGray.dark,
                      }}
                    />
                  </Box>
                  <Box
                    flexGrow={0}
                    p={1}
                    style={{
                      background: `linear-gradient(135deg, ${gradientPrimaryRgb} 0%, ${gradientSecondaryRgb} 100%)`,
                      color: "white",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color="inherit"
                      noWrap
                      align="left"
                    >
                      {name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </ButtonBase>
        </ThemeProvider>
      </StyledEngineProvider>
    </Grid>
  );
};

const OffsetPurchaseBlock = ({
  displayProjects,
  setSelectedProject,
  eventName,
  negativeFootprint,
  companyName,
}) => {
  const { isMobile, theme } = useLayoutHelpers();

  const sharedSubtitle = `You can learn more about each offset by ${
    isMobile ? "tapping" : "clicking"
  } on the photo.`;

  return (
    <PageSectionPaper
      backgroundColor={theme.palette.secondary.dark}
      theme={mergeDarkTheme}
      title={
        negativeFootprint
          ? `Even though your footprint is negative, do you want to help ${companyName} make ${eventName} a carbon nuetral event by purchasing a few extra carbon offsets to cover a fellow event attendee?`
          : `Choose a carbon offset project below to neutralize your ${eventName} Footprint!`
      }
      subtitle={
        negativeFootprint
          ? `Here are ${companyName} and Aclymate's best picks for carbon offsets projects. ${sharedSubtitle}`
          : `The purchase prices cover your total ${eventName} footprint. ${companyName} and Aclymate have vetted these ${
              displayProjects?.length || 4
            } projects, but it is up to you to choose which one you want to support. ${sharedSubtitle}`
      }
      tooltip={
        <Typography variant="body2">
          Carbon offsets are generated by projects that meaningfully and
          measurably pull carbon dioxide out of the atmosphere, or prevent it
          from being emitted in the first place. By offsetting your carbon
          footprint for the {eventName} you are purchasing an equivalent volume
          of carbon dioxide that will no longer contribute to climate change.
        </Typography>
      }
    >
      <Grid spacing={2} container justifyContent="space-around">
        {displayProjects.map((project, idx) => (
          <DisplayProjectCard
            key={`display-project-card-${idx}`}
            onClick={() => setSelectedProject(project)}
            negativeFootprint={negativeFootprint}
            {...project}
          />
        ))}
      </Grid>
    </PageSectionPaper>
  );
};

const OffsetsPurchaseSuccessBlock = ({
  negativeFootprint,
  travelGroup,
  name,
  slug,
  purchaser,
}) => {
  const { name: purchaserName } = purchaser || {};

  const socialShareUrl =
    "https://bluegrass.com/telluride/sustainable-festivation/what-you-can-do";

  return (
    <Grid item>
      <DefaultPaper style={{ background: "rgba(255, 255, 255, 0.9)" }}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h5" align="center">
              {negativeFootprint
                ? `Congratulations! By attending the Festival you are potentially emitting less carbon in comparison to your normal daily activities.`
                : `Congratulations, your emissions for the ${name} are fully offset`}
            </Typography>
            {!!purchaserName && (
              <Typography
                variant="subtitle2"
                align="center"
                color="textSecondary"
              >{`${purchaserName} purchased offsets for your whole group when they filled out their survey`}</Typography>
            )}
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            spacing={1}
          >
            <Grid item md={10}>
              <Typography variant="subtitle1" align="center">
                {!!travelGroup || negativeFootprint
                  ? `Use social media to let everyone know how excited you are to be going to the ${name}`
                  : "Share your achievement on social media and encourage other attendees to offset their event emissions"}
              </Typography>
            </Grid>
            <Grid item container spacing={2} justifyContent="center">
              <Grid item>
                <FacebookShareButton url={socialShareUrl}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </Grid>
              <Grid item>
                <TwitterShareButton url={socialShareUrl}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
              </Grid>
              <Grid item>
                <LinkedinShareButton url={socialShareUrl}>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DefaultPaper>
    </Grid>
  );
};

const EventPrimaryPage = () => {
  const { eventSlug, surveyType, refresh } = useParams();

  const [cookies] = useCookies(["aclymate-in-slug", "aclymate-event-partner"]);
  const { isMobile, isMedium } = useLayoutHelpers();
  const { formLoading } = useSharedFormLoading();

  const [selectedProject, setSelectedProject] = useState(null);
  const [serverData, setServerData] = useState({});
  const [serverDataLoading, setServerDataLoading] = useState({});
  const [displayProjects, setDisplayProject] = useState([]);

  const {
    survey = {},
    attendee = {},
    offsets = [],
    travelGroup,
    eventData = {},
  } = serverData;

  const {
    id: eventId,
    name: eventName,
    preferredOffsets,
    companyName,
    disabled,
    disabledText,
  } = eventData || {};
  const {
    totalEventTonsCo2e,
    avoidedEmissions = {},
    travel = {},
    lodging = {},
    confirmed,
  } = survey || {};
  const { groupSize: travelGroupSize } = travel;
  const { groupSize: lodgingGroupSize } = lodging;
  const { name = "", slug, email } = attendee || {};

  useEffect(() => {
    const fetchAttendeeData = async () => {
      if (!!refresh) {
        return;
      }

      const attendeeCookie = cookies["aclymate-in-slug"];
      if (attendeeCookie && surveyType === "attendee") {
        return await fetchOurApi({
          path: `/events/attendee/${attendeeCookie}/${eventSlug}`,
          method: "GET",
          callback: (res) => res,
        });
      }

      const partnerCookie = cookies["aclymate-event-partner"];
      if (partnerCookie && surveyType === "partner") {
        return await fetchOurApi({
          path: `/events/partner/${partnerCookie}/${eventSlug}`,
          method: "GET",
          callback: (res) => res,
        });
      }

      return {};
    };

    const fetchData = async () => {
      const [attendee, eventData] = await Promise.all([
        fetchAttendeeData(),
        fetchOurApi({
          path: `/events/event/${eventSlug}`,
          method: "GET",
          callback: (res) => res,
        }),
      ]);

      if (eventData?.success === false) {
        setServerDataLoading(false);
        return setServerData({ error: eventData.message });
      }

      setServerDataLoading(false);
      return setServerData({ ...attendee, eventData });
    };

    if (serverDataLoading) {
      fetchData();
    }
  }, [cookies, surveyType, eventSlug, serverDataLoading, refresh]);

  useEffect(() => {
    if (slug && email) {
      Sentry.configureScope((scope) => scope.clear());
      Sentry.setContext("Event Individual", { slug, email });
    }
  }, [slug, email]);

  useEffect(() => {
    const fetchAndSetPreferredProjectsData = async () => {
      const preferredProjects = await Promise.all(
        preferredOffsets.map((slug) =>
          fetchAdminApi({
            url: `/offsets/fetch-single-offset/${slug}`,
            data: { method: "GET" },
          })
        )
      );
      const formattedProjects = preferredProjects.map(formatProject);
      setDisplayProject(
        formattedProjects.map(
          ({ totalThousandLbsCost, ...otherProjectProps }) => ({
            ...otherProjectProps,
            totalThousandLbsCost,
            tag: "event",
            displayCost: displayEventOffsetCost(
              totalEventTonsCo2e,
              totalThousandLbsCost
            ),
          })
        )
      );
    };

    if (!displayProjects.length && preferredOffsets && totalEventTonsCo2e) {
      fetchAndSetPreferredProjectsData();
    }
  }, [preferredOffsets, totalEventTonsCo2e, displayProjects.length]);

  const editServerData = (field) => (value) =>
    editObjectData(setServerData, field, value);

  const displayPurchasedOffsets = () => {
    const [individualPurchaseOffset] = offsets.filter((offset) => offset);

    if (individualPurchaseOffset) {
      return individualPurchaseOffset;
    }

    const { offsetPurchase } = travelGroup || {};
    return offsetPurchase;
  };

  const purchasedOffset = displayPurchasedOffsets();

  const eventLayoutProps = confirmed
    ? { backdropOpacity: 0.5, backdropColor: "255, 255, 255" }
    : { backdropOpacity: 0.8, backdropColor: "255, 255, 255" };

  const negativeFootprint = purchasedOffset || totalEventTonsCo2e <= 0;

  const surveyTypes = {
    attendee: (
      <EventAttendeeSurvey
        eventData={eventData}
        serverData={serverData}
        setServerData={setServerData}
      />
    ),
    partner: (
      <PartnerSurvey eventData={eventData} setServerData={setServerData} />
    ),
  };

  return (
    <>
      {selectedProject && (
        <OffsetDetailsPurchaseSlider
          onSlideClose={() => setSelectedProject(null)}
          project={selectedProject}
          ProjectPurchaseFlow={EventOffsetPurchase}
          purchaseFlowProps={{
            totalEventTonsCo2e,
            event: { slug: eventSlug, id: eventId },
            individual: attendee,
            groupSize:
              lodgingGroupSize > travelGroupSize
                ? lodgingGroupSize
                : travelGroupSize,
            editServerData,
            negativeFootprint,
            surveyType,
          }}
        />
      )}
      {serverData.error ? (
        <EventNotFound />
      ) : serverDataLoading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ height: "100vh" }}
        >
          <AnimatedLogo />
        </Box>
      ) : (
        <EventLayout {...eventData} {...eventLayoutProps}>
          {disabled ? (
            <Box
              p={isMobile ? 1 : isMedium ? 4 : 10}
              style={{ height: "70vh" }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h4" align="center">
                {disabledText}
              </Typography>
            </Box>
          ) : !confirmed && !formLoading ? (
            surveyTypes[surveyType]
          ) : (
            <Box p={isMobile ? 1 : isMedium ? 4 : 10}>
              <Container maxWidth="md" disableGutters={isMobile}>
                <Grid container direction="column" rowGap={isMobile ? 1 : 2}>
                  {!formLoading && (
                    <Grid item>
                      <Typography variant="h2" color="textPrimary">{`Thanks ${
                        surveyType === "attendee" ? nameToFirstName(name) : name
                      }!`}</Typography>
                      <Typography variant="subtitle1" color="textSecondary">
                        By participating in this survey you are helping refine
                        our total estimated Event Carbon Footprint and by
                        voluntarily purchasing offsets you are contributing to
                        making this event more carbon neutral.
                      </Typography>
                    </Grid>
                  )}
                  {formLoading ? (
                    <Box
                      p={6}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      style={{ height: "100vh" }}
                    >
                      <AnimatedLogo />
                    </Box>
                  ) : (
                    <>
                      {negativeFootprint && (
                        <OffsetsPurchaseSuccessBlock
                          key="offsets-success-block"
                          negativeFootprint={totalEventTonsCo2e <= 0}
                          {...purchasedOffset}
                          {...eventData}
                        />
                      )}
                      <ImpactBreakdown
                        totalEventTonsCo2e={totalEventTonsCo2e}
                        lodging={lodging}
                        travel={travel}
                        avoidedEmissions={avoidedEmissions}
                      />
                      <AttendeeImpactDetails
                        lodging={lodging}
                        travel={travel}
                        avoidedEmissions={avoidedEmissions}
                        totalEventTonsCo2e={totalEventTonsCo2e}
                        eventName={eventName}
                        surveyType={surveyType}
                      />
                      <OffsetPurchaseBlock
                        key="offsets-purchase-block"
                        displayProjects={displayProjects}
                        setSelectedProject={setSelectedProject}
                        eventName={eventName}
                        negativeFootprint={negativeFootprint}
                        companyName={companyName}
                      />
                      {negativeFootprint && surveyType === "attendee" && (
                        <MyAclymateCta />
                      )}
                    </>
                  )}
                </Grid>
              </Container>
            </Box>
          )}
        </EventLayout>
      )}
    </>
  );
};
export default EventPrimaryPage;
